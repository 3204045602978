export const testSource = 'B2B';

export const partnerIds = {
  consoleflare: '640ec8835ecc94001441e744',
  deviare: '610a7ca8d3c51a1458d005ce',
  nirman: '66b99825e72e50001534c8c0',
  belong: '620a15895763cf0016c6b31f',
  gsap: '61dbfd5614a52000231aa3b8',
  entreprenuship: '670f51833f7af0001560f3e2',
  tapmi: '673ad227dfcc0a00156bbe27',
  belongext: '675141894ea46b0015cad6de',
  belongFsd: '678e9a25d4822f0015665c92',
};

export const dataAnalyticsDomainId = '668bb2fc3dc68400154ce86a';

export const vgeBatch = ['64feb02a602d910014f845f7'];

export const allBatches = { deviareMICT: '64f2077ec0020b0014ccec3d' };

export const finalQuizBatches = ['66b5a13bf3148a0015709472'];
