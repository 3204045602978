import React from 'react';
// import ReactCountryFlag from 'react-country-flag';
// import { useGetCountriesQuery } from '../userBatchSlice';
import useBasicDetailsContext from 'hooks/useBasicDetailsContext';
import { BannerType } from 'components/dashboard/vga/types';
import { MdOutlineOndemandVideo } from 'react-icons/md';
import { useGetUserQuery } from 'components/profile/userSlice';

const SuperBanner: React.FC<BannerType> = ({
  programTitle,
  companyName,
  companyLogo,
  companyCountry,
  domain,
  guidebook,
  batchName,
  company2Name,
  company2Logo,
  company2Country,
  totalWeek,
  companyWebsite,
  tutorial,
}) => {
  // const { data } = useGetCountriesQuery({});
  const { basicDetails } = useBasicDetailsContext();
  const isFullJourney = basicDetails?.isFullJourney;
  // const company_country =
  //   isFullJourney && company2Country ? company2Country : companyCountry;
  //   let countryCode = '';
  //   if (data) {
  //     countryCode = Object.keys(data).filter((el: any) => {
  //       return data[el]?.name === company_country;
  //     })[0];
  //   }

  const company_name =
    isFullJourney && company2Name ? company2Name : companyName;

  const company_logo =
    isFullJourney && company2Logo ? company2Logo : companyLogo;

  const lastAuthUser = localStorage.getItem(
    `CognitoIdentityServiceProvider.20738icdfn538kieuetqrajoj2.LastAuthUser`
  );
  console.log(lastAuthUser);
  const idToken = localStorage.getItem(
    `CognitoIdentityServiceProvider.20738icdfn538kieuetqrajoj2.${lastAuthUser}.idToken`
  );
  const accessToken = localStorage.getItem(
    `CognitoIdentityServiceProvider.20738icdfn538kieuetqrajoj2.${lastAuthUser}.accessToken`
  );
  const refreshToken =
    localStorage.getItem(
      `CognitoIdentityServiceProvider.20738icdfn538kieuetqrajoj2.${lastAuthUser}.refreshToken`
    ) || '';
  const userDataC = localStorage.getItem(
    `CognitoIdentityServiceProvider.20738icdfn538kieuetqrajoj2.${lastAuthUser}.userData`
  );

  let refUrl = '';
  if (idToken && accessToken && userDataC && lastAuthUser) {
    refUrl = `https://mycoding.belong.education/deviare?idToken=${encodeURIComponent(
      idToken
    )}&accessToken=${encodeURIComponent(
      accessToken
    )}&refreshToken=${encodeURIComponent(
      refreshToken
    )}&userData=${encodeURIComponent(userDataC)}
       &lastAuthUser=${lastAuthUser}`;
  }

  const fsdUser = [
    'nmjokovane@gmail.com',
    'zomzimpelane42@gmail.com',
    'zusiphemakeleni15@gmail.com',
    'zusiphetshayisa@gmail.com',
    'dlalaakhona@gmail.com',
    'vincemoloi@outlook.com',
    'mukesh@ingeniousfaces.com',
  ];

  const { data: userData } = useGetUserQuery();

  const showFSD = fsdUser.includes(userData?.email);

  return (
    <div className='py-2 lg:py-3   border-t border-b px-8 -mx-4  overflow-hidden bg-[#F8FCFC] flex flex-col lg:flex-row gap-4'>
      {/* details */}
      <div className='space-y-2 lg:basis-3/4'>
        {/* <p className='font-semibold text-xl'>Virtual Global Apprenticeship </p> */}

        <p className='font-semibold'>
          {/* You are assigned to {company_name} company
          <br />
          <span className='text-sm'>
            {programTitle} - {batchName}
          </span> */}
          {programTitle} with {company_name}
          <p className='text-sm'>{batchName}</p>
        </p>
        <div className='flex gap-4 md:gap-8 flex-wrap'>
          {/* time */}
          <div className='flex space-x-2 items-center font-normal'>
            <svg
              width='16'
              height='17'
              viewBox='0 0 16 17'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M7.99992 15.1666C4.31802 15.1666 1.33325 12.1818 1.33325 8.49992C1.33325 4.81802 4.31802 1.83325 7.99992 1.83325C11.6818 1.83325 14.6666 4.81802 14.6666 8.49992C14.6666 12.1818 11.6818 15.1666 7.99992 15.1666ZM7.99992 13.8333C10.9455 13.8333 13.3333 11.4455 13.3333 8.49992C13.3333 5.5544 10.9455 3.16659 7.99992 3.16659C5.0544 3.16659 2.66659 5.5544 2.66659 8.49992C2.66659 11.4455 5.0544 13.8333 7.99992 13.8333ZM8.66658 8.49992H11.3333V9.83325H7.33325V5.16658H8.66658V8.49992Z'
                fill='#10ABB7'
              />
            </svg>

            <p>{totalWeek} weeks</p>
          </div>
          {/* domain */}
          <div className='flex space-x-2 items-center font-normal'>
            <svg
              width='16'
              height='17'
              viewBox='0 0 16 17'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M3.55295 2.68097C4.37501 2.38872 5.3282 2.57158 5.98618 3.22956C6.73347 3.97683 6.86793 5.10486 6.38953 5.98914L13.5287 13.129L12.5859 14.0719L5.44655 6.93242C4.56217 7.41116 3.43379 7.27682 2.68635 6.52939C2.02818 5.87123 1.8454 4.91768 2.13801 4.09546L3.62915 5.58658C4.01968 5.9771 4.65285 5.9771 5.04337 5.58658C5.43389 5.19606 5.43389 4.56289 5.04337 4.17237L3.55295 2.68097ZM10.4645 3.93666L12.5859 2.75815L13.5287 3.70096L12.3501 5.82228L11.1716 6.05798L9.7574 7.47222L8.8146 6.52939L10.2288 5.11518L10.4645 3.93666ZM5.98618 9.35783L6.929 10.3006L3.39345 13.8362C3.13311 14.0965 2.71099 14.0965 2.45065 13.8362C2.20889 13.5944 2.19163 13.2132 2.39884 12.9515L2.45065 12.8934L5.98618 9.35783Z'
                fill='#10ABB7'
              />
            </svg>
            <p>{domain}</p>
          </div>

          <div className='flex space-x-2 items-center font-medium'>
            <svg
              width='16'
              height='17'
              viewBox='0 0 16 17'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'>
              <path
                d='M6.66667 2.5V3.83333H3.33333V13.1667H12.6667V9.83333H14V13.8333C14 14.2015 13.7015 14.5 13.3333 14.5H2.66667C2.29848 14.5 2 14.2015 2 13.8333V3.16667C2 2.79848 2.29848 2.5 2.66667 2.5H6.66667ZM11.7239 3.83333H8.66667V2.5H14V7.83333H12.6667V4.77614L8 9.4428L7.0572 8.5L11.7239 3.83333Z'
                fill='#10ABB7'
              />
            </svg>

            <p className='text-primary underline text-small font-normal'>
              <a href={companyWebsite}>Go to website</a>
            </p>
          </div>
          {showFSD && (
            <div className='border border-primary rounded-lg px-3 font-bold'>
              <a
                className='text-green-500 text-lg'
                href={refUrl}
                target='_blank'
                rel='noreferrer'>
                Join Bootcamp
              </a>
            </div>
          )}
        </div>
      </div>
      {/* Guide book, faq and company logo */}
      <div className='flex  lg:basis-1/4 lg:-my-6'>
        {/* guide book and faq */}
        <div className='space-y-2  lg:flex lg:flex-col lg:justify-center lg:border-l lg:border-r px-4 lg:pl-4 lg:pr-10 text-sm'>
          <div>
            <a
              href={guidebook}
              target='_blank'
              rel='noreferrer noopenener'
              className='flex items-center space-x-2 font-semibold'>
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M2.5 15.4166V4.16663C2.5 2.78592 3.61929 1.66663 5 1.66663H16.6667C17.1269 1.66663 17.5 2.03973 17.5 2.49996V17.5C17.5 17.9602 17.1269 18.3333 16.6667 18.3333H5.41667C3.80583 18.3333 2.5 17.0275 2.5 15.4166ZM15.8333 16.6666V14.1666H5.41667C4.72631 14.1666 4.16667 14.7263 4.16667 15.4166C4.16667 16.107 4.72631 16.6666 5.41667 16.6666H15.8333ZM8.33333 3.33329H5C4.53977 3.33329 4.16667 3.70639 4.16667 4.16663V12.7806C4.54552 12.6006 4.96933 12.5 5.41667 12.5H15.8333V3.33329H14.1667V9.99996L11.25 8.33329L8.33333 9.99996V3.33329Z'
                  fill='#10ABB7'
                />
              </svg>
              <p className='underline'>Guide book</p>
            </a>
          </div>
          <div>
            <a
              href='https://drive.google.com/file/d/1TCaLjgigl6qMKL8Q3EveiD_mH4knV-ds/view?usp=sharing'
              target='_blank'
              rel='noreferrer noopenener'
              className='flex items-center space-x-2'>
              <svg
                width='20'
                height='20'
                viewBox='0 0 20 20'
                fill='none'
                xmlns='http://www.w3.org/2000/svg'>
                <path
                  d='M10.0001 18.3334C5.39771 18.3334 1.66675 14.6024 1.66675 10.0001C1.66675 5.39771 5.39771 1.66675 10.0001 1.66675C14.6024 1.66675 18.3334 5.39771 18.3334 10.0001C18.3334 14.6024 14.6024 18.3334 10.0001 18.3334ZM10.0001 16.6667C13.682 16.6667 16.6667 13.682 16.6667 10.0001C16.6667 6.31818 13.682 3.33341 10.0001 3.33341C6.31818 3.33341 3.33341 6.31818 3.33341 10.0001C3.33341 13.682 6.31818 16.6667 10.0001 16.6667ZM9.16675 12.5001H10.8334V14.1667H9.16675V12.5001ZM10.8334 11.1293V11.6667H9.16675V10.4167C9.16675 9.9565 9.53983 9.58341 10.0001 9.58341C10.6904 9.58341 11.2501 9.02375 11.2501 8.33341C11.2501 7.64306 10.6904 7.08341 10.0001 7.08341C9.39366 7.08341 8.88808 7.51527 8.77408 8.08822L7.13951 7.7613C7.40539 6.42441 8.58508 5.41675 10.0001 5.41675C11.6109 5.41675 12.9167 6.72258 12.9167 8.33341C12.9167 9.65466 12.0382 10.7707 10.8334 11.1293Z'
                  fill='#10ABB7'
                />
              </svg>

              <p className='underline'>FAQs</p>
            </a>
          </div>
          <div>
            <a
              href={tutorial}
              target='_blank'
              rel='noreferrer noopenener'
              className='flex items-center space-x-2'>
              <MdOutlineOndemandVideo className='text-primary text-lg' />
              <p className='underline'>Tutorial video</p>
            </a>
          </div>
        </div>
        {/* company logo */}
        <div className='lg:flex lg:flex-col lg:justify-center lg:mx-auto'>
          <img
            className='h-16 object-contain'
            src={company_logo}
            alt='company logo'
          />
        </div>
      </div>
    </div>
  );
};

export default SuperBanner;
